import { CHIME_NETWORK_QUALITY_TIMEOUT, CHIME_NETWORK_TEST_STATUS } from 'frontend/constants/chime';
import { NETWORK_QUALITY_SCORE } from 'frontend/utils/network-connection';
import { dependentKeyCompat } from '@ember/object/compat';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { reads } from 'macro-decorators';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class ChimeReadinessCheckService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service('chime.sdk') sdkService;

  @reads('sdkService.sdk') chimeSdk;

  @tracked networkUdpTestResult;
  @tracked networkTcpTestResult;
  meetingReadinessChecker = null;

  get isNetworkConnectionExcellent() {
    let results = [this.networkUdpTestResult, this.networkTcpTestResult].filter(
      result => result !== undefined
    );
    return results.every(result => result === CHIME_NETWORK_TEST_STATUS.Succeeded);
  }

  get isNetworkConnectionPoor() {
    return (
      this.networkTcpTestResult === CHIME_NETWORK_TEST_STATUS.Succeeded ||
      this.networkUdpTestResult === CHIME_NETWORK_TEST_STATUS.Succeeded
    );
  }

  testNetworkConnection = task(async () => {
    await this.performUdpNetworkTest.perform();
    await this.performTcpNetworkTest.perform();
    if (isEmberTesting()) return;
    await timeout(CHIME_NETWORK_QUALITY_TIMEOUT);
    this.testNetworkConnection.perform();
  });

  performUdpNetworkTest = task(async () => {
    this.networkUdpTestResult = await this.meetingReadinessChecker.checkNetworkUDPConnectivity();
  });

  performTcpNetworkTest = task(async () => {
    this.networkTcpTestResult = await this.meetingReadinessChecker.checkNetworkTCPConnectivity();
  });

  cancelNetworkConnectionTasks() {
    [this.performUdpNetworkTest, this.performTcpNetworkTest, this.testNetworkConnection].forEach(
      task => {
        if (task.isRunning) task.cancelAll();
      }
    );
  }

  initMeetingReadinessChecker() {
    this.meetingReadinessChecker = new this.chimeSdk.DefaultMeetingReadinessChecker(
      this.meetingManager.logger,
      this.meetingManager.meetingSession
    );
  }

  @dependentKeyCompat
  get networkQualityScore() {
    if (this.networkUdpTestResult === undefined && this.networkTcpTestResult === undefined) {
      return undefined;
    }

    if (this.isNetworkConnectionExcellent) {
      return NETWORK_QUALITY_SCORE.excellent;
    } else if (this.isNetworkConnectionPoor) {
      return NETWORK_QUALITY_SCORE.poor;
    } else {
      return NETWORK_QUALITY_SCORE.noConnection;
    }
  }

  start() {
    this.initMeetingReadinessChecker();
    this.testNetworkConnection.perform();
  }

  stop() {
    this.cancelNetworkConnectionTasks();
    this.meetingReadinessChecker = null;
  }
}
