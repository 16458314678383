import { loadRestJsonErrorCode, parseJsonBody as parseBody, parseJsonErrorBody as parseErrorBody } from "@aws-sdk/core";
import { requestBuilder as rb } from "@smithy/core";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectObject as __expectObject, expectString as __expectString, map, parseEpochTimestamp as __parseEpochTimestamp, take, withBaseException, } from "@smithy/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
import { ChimeSDKMessagingServiceException as __BaseException } from "../models/ChimeSDKMessagingServiceException";
import { BadRequestException, ConflictException, ForbiddenException, NotFoundException, ResourceLimitExceededException, ServiceFailureException, ServiceUnavailableException, ThrottledClientException, UnauthorizedClientException, } from "../models/models_0";
export const se_AssociateChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/channel-flow");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ChannelFlowArn: [],
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_BatchCreateChannelMembershipCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_o]: [, "batch-create"],
    });
    let body;
    body = JSON.stringify(take(input, {
        MemberArns: (_) => _json(_),
        SubChannelId: [],
        Type: [],
    }));
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_ChannelFlowCallbackCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/channels/{ChannelArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_o]: [, "channel-flow-callback"],
    });
    let body;
    body = JSON.stringify(take(input, {
        CallbackId: [true, (_) => _ ?? generateIdempotencyToken()],
        ChannelMessage: (_) => _json(_),
        DeleteResource: [],
    }));
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_CreateChannelCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels");
    let body;
    body = JSON.stringify(take(input, {
        AppInstanceArn: [],
        ChannelId: [],
        ClientRequestToken: [true, (_) => _ ?? generateIdempotencyToken()],
        ElasticChannelConfiguration: (_) => _json(_),
        ExpirationSettings: (_) => _json(_),
        MemberArns: (_) => _json(_),
        Metadata: [],
        Mode: [],
        ModeratorArns: (_) => _json(_),
        Name: [],
        Privacy: [],
        Tags: (_) => _json(_),
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateChannelBanCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/bans");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        MemberArn: [],
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/channel-flows");
    let body;
    body = JSON.stringify(take(input, {
        AppInstanceArn: [],
        ClientRequestToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Name: [],
        Processors: (_) => _json(_),
        Tags: (_) => _json(_),
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateChannelMembershipCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        MemberArn: [],
        SubChannelId: [],
        Type: [],
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateChannelModeratorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/moderators");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ChannelModeratorArn: [],
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_DeleteChannelCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteChannelBanCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/bans/{MemberArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/channel-flows/{ChannelFlowArn}");
    b.p("ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteChannelMembershipCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships/{MemberArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MemberArn", () => input.MemberArn, "{MemberArn}", false);
    const query = map({
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_DeleteChannelMessageCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages/{MessageId}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_DeleteChannelModeratorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/moderators/{ChannelModeratorArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("ChannelModeratorArn", () => input.ChannelModeratorArn, "{ChannelModeratorArn}", false);
    let body;
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteMessagingStreamingConfigurationsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/app-instances/{AppInstanceArn}/streaming-configurations");
    b.p("AppInstanceArn", () => input.AppInstanceArn, "{AppInstanceArn}", false);
    let body;
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DescribeChannelCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeChannelBanCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/bans/{MemberArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/channel-flows/{ChannelFlowArn}");
    b.p("ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeChannelMembershipCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships/{MemberArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MemberArn", () => input.MemberArn, "{MemberArn}", false);
    const query = map({
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_DescribeChannelMembershipForAppInstanceUserCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_s]: [, "app-instance-user-membership"],
        [_aiua]: [, __expectNonNull(input[_AIUA], `AppInstanceUserArn`)],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_DescribeChannelModeratedByAppInstanceUserCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_s]: [, "app-instance-user-moderated-channel"],
        [_aiua]: [, __expectNonNull(input[_AIUA], `AppInstanceUserArn`)],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_DescribeChannelModeratorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/moderators/{ChannelModeratorArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("ChannelModeratorArn", () => input.ChannelModeratorArn, "{ChannelModeratorArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DisassociateChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/channel-flow/{ChannelFlowArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_GetChannelMembershipPreferencesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships/{MemberArn}/preferences");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_GetChannelMessageCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages/{MessageId}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetChannelMessageStatusCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages/{MessageId}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        [_s]: [, "message-status"],
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetMessagingSessionEndpointCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/endpoints/messaging-session");
    let body;
    body = "";
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_GetMessagingStreamingConfigurationsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/app-instances/{AppInstanceArn}/streaming-configurations");
    b.p("AppInstanceArn", () => input.AppInstanceArn, "{AppInstanceArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_ListChannelBansCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/bans");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelFlowsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/channel-flows");
    const query = map({
        [_aia]: [, __expectNonNull(input[_AIA], `AppInstanceArn`)],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelMembershipsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_t]: [, input[_T]],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelMembershipsForAppInstanceUserCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels");
    const query = map({
        [_s]: [, "app-instance-user-memberships"],
        [_aiua]: [, input[_AIUA]],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelMessagesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_so]: [, input[_SO]],
        [_nb]: [() => input.NotBefore !== void 0, () => (input[_NB].toISOString().split(".")[0] + "Z").toString()],
        [_na]: [() => input.NotAfter !== void 0, () => (input[_NA].toISOString().split(".")[0] + "Z").toString()],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
        [_sci]: [, input[_SCI]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelModeratorsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/moderators");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels");
    const query = map({
        [_aia]: [, __expectNonNull(input[_AIA], `AppInstanceArn`)],
        [_p]: [, input[_P]],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelsAssociatedWithChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/channels");
    const query = map({
        [_s]: [, "channel-flow-associations"],
        [_cfa]: [, __expectNonNull(input[_CFA], `ChannelFlowArn`)],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListChannelsModeratedByAppInstanceUserCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels");
    const query = map({
        [_s]: [, "app-instance-user-moderated-channels"],
        [_aiua]: [, input[_AIUA]],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListSubChannelsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/subchannels");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListTagsForResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tags");
    const query = map({
        [_a]: [, __expectNonNull(input[_RARN], `ResourceARN`)],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_PutChannelExpirationSettingsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/expiration-settings");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ExpirationSettings: (_) => _json(_),
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_PutChannelMembershipPreferencesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/memberships/{MemberArn}/preferences");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Preferences: (_) => _json(_),
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_PutMessagingStreamingConfigurationsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/app-instances/{AppInstanceArn}/streaming-configurations");
    b.p("AppInstanceArn", () => input.AppInstanceArn, "{AppInstanceArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        StreamingConfigurations: (_) => _json(_),
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_RedactChannelMessageCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages/{MessageId}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        [_o]: [, "redact"],
    });
    let body;
    body = JSON.stringify(take(input, {
        SubChannelId: [],
    }));
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_SearchChannelsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels");
    const query = map({
        [_o]: [, "search"],
        [_mr]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
        [_nt]: [, input[_NT]],
    });
    let body;
    body = JSON.stringify(take(input, {
        Fields: (_) => _json(_),
    }));
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_SendChannelMessageCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ClientRequestToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Content: [],
        ContentType: [],
        MessageAttributes: (_) => _json(_),
        Metadata: [],
        Persistence: [],
        PushNotification: (_) => _json(_),
        SubChannelId: [],
        Target: (_) => _json(_),
        Type: [],
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_TagResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tags");
    const query = map({
        [_o]: [, "tag-resource"],
    });
    let body;
    body = JSON.stringify(take(input, {
        ResourceARN: [],
        Tags: (_) => _json(_),
    }));
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_UntagResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tags");
    const query = map({
        [_o]: [, "untag-resource"],
    });
    let body;
    body = JSON.stringify(take(input, {
        ResourceARN: [],
        TagKeys: (_) => _json(_),
    }));
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_UpdateChannelCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Metadata: [],
        Mode: [],
        Name: [],
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_UpdateChannelFlowCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/channel-flows/{ChannelFlowArn}");
    b.p("ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Name: [],
        Processors: (_) => _json(_),
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_UpdateChannelMessageCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/messages/{MessageId}");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    b.p("MessageId", () => input.MessageId, "{MessageId}", false);
    let body;
    body = JSON.stringify(take(input, {
        Content: [],
        ContentType: [],
        Metadata: [],
        SubChannelId: [],
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_UpdateChannelReadMarkerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = map({}, isSerializableHeaderValue, {
        [_xacb]: input[_CB],
    });
    b.bp("/channels/{ChannelArn}/readMarker");
    b.p("ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const de_AssociateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_BatchCreateChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        BatchChannelMemberships: _json,
        Errors: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ChannelFlowCallbackCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CallbackId: __expectString,
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateChannelCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateChannelBanCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlowArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateChannelModeratorCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelModerator: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DeleteChannelCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteChannelBanCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteChannelModeratorCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteMessagingStreamingConfigurationsCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DescribeChannelCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channel: (_) => de_Channel(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeChannelBanCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelBan: (_) => de_ChannelBan(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlow: (_) => de_ChannelFlow(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMembership: (_) => de_ChannelMembership(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeChannelMembershipForAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMembership: (_) => de_ChannelMembershipForAppInstanceUserSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeChannelModeratedByAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channel: (_) => de_ChannelModeratedByAppInstanceUserSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeChannelModeratorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelModerator: (_) => de_ChannelModerator(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DisassociateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_GetChannelMembershipPreferencesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
        Preferences: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMessage: (_) => de_ChannelMessage(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetChannelMessageStatusCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Status: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetMessagingSessionEndpointCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Endpoint: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetMessagingStreamingConfigurationsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        StreamingConfigurations: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelBansCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelBans: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelFlowsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlows: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelMembershipsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelMemberships: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelMembershipsForAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMemberships: (_) => de_ChannelMembershipForAppInstanceUserSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelMessagesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelMessages: (_) => de_ChannelMessageSummaryList(_, context),
        NextToken: __expectString,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelModeratorsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelModerators: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: (_) => de_ChannelSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelsAssociatedWithChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListChannelsModeratedByAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: (_) => de_ChannelModeratedByAppInstanceUserSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListSubChannelsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        NextToken: __expectString,
        SubChannels: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListTagsForResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Tags: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_PutChannelExpirationSettingsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ExpirationSettings: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_PutChannelMembershipPreferencesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
        Preferences: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_PutMessagingStreamingConfigurationsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        StreamingConfigurations: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_RedactChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        MessageId: __expectString,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_SearchChannelsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: (_) => de_ChannelSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_SendChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        MessageId: __expectString,
        Status: _json,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_TagResourceCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_UntagResourceCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_UpdateChannelCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlowArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        MessageId: __expectString,
        Status: _json,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateChannelReadMarkerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
const throwDefaultError = withBaseException(__BaseException);
const de_BadRequestExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new BadRequestException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ConflictExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ConflictException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ForbiddenExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ForbiddenException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_NotFoundExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new NotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceLimitExceededExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ResourceLimitExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceFailureExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ServiceFailureException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceUnavailableExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ServiceUnavailableException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ThrottledClientExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ThrottledClientException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_UnauthorizedClientExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new UnauthorizedClientException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_AppInstanceUserMembershipSummary = (output, context) => {
    return take(output, {
        ReadMarkerTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        SubChannelId: __expectString,
        Type: __expectString,
    });
};
const de_Channel = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        ChannelFlowArn: __expectString,
        CreatedBy: _json,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        ElasticChannelConfiguration: _json,
        ExpirationSettings: _json,
        LastMessageTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Metadata: __expectString,
        Mode: __expectString,
        Name: __expectString,
        Privacy: __expectString,
    });
};
const de_ChannelBan = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        CreatedBy: _json,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Member: _json,
    });
};
const de_ChannelFlow = (output, context) => {
    return take(output, {
        ChannelFlowArn: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Name: __expectString,
        Processors: _json,
    });
};
const de_ChannelMembership = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        InvitedBy: _json,
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Member: _json,
        SubChannelId: __expectString,
        Type: __expectString,
    });
};
const de_ChannelMembershipForAppInstanceUserSummary = (output, context) => {
    return take(output, {
        AppInstanceUserMembershipSummary: (_) => de_AppInstanceUserMembershipSummary(_, context),
        ChannelSummary: (_) => de_ChannelSummary(_, context),
    });
};
const de_ChannelMembershipForAppInstanceUserSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelMembershipForAppInstanceUserSummary(entry, context);
    });
    return retVal;
};
const de_ChannelMessage = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        Content: __expectString,
        ContentType: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastEditedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        MessageAttributes: _json,
        MessageId: __expectString,
        Metadata: __expectString,
        Persistence: __expectString,
        Redacted: __expectBoolean,
        Sender: _json,
        Status: _json,
        SubChannelId: __expectString,
        Target: _json,
        Type: __expectString,
    });
};
const de_ChannelMessageSummary = (output, context) => {
    return take(output, {
        Content: __expectString,
        ContentType: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastEditedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        MessageAttributes: _json,
        MessageId: __expectString,
        Metadata: __expectString,
        Redacted: __expectBoolean,
        Sender: _json,
        Status: _json,
        Target: _json,
        Type: __expectString,
    });
};
const de_ChannelMessageSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelMessageSummary(entry, context);
    });
    return retVal;
};
const de_ChannelModeratedByAppInstanceUserSummary = (output, context) => {
    return take(output, {
        ChannelSummary: (_) => de_ChannelSummary(_, context),
    });
};
const de_ChannelModeratedByAppInstanceUserSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelModeratedByAppInstanceUserSummary(entry, context);
    });
    return retVal;
};
const de_ChannelModerator = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        CreatedBy: _json,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Moderator: _json,
    });
};
const de_ChannelSummary = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        LastMessageTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Metadata: __expectString,
        Mode: __expectString,
        Name: __expectString,
        Privacy: __expectString,
    });
};
const de_ChannelSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelSummary(entry, context);
    });
    return retVal;
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const isSerializableHeaderValue = (value) => value !== undefined &&
    value !== null &&
    value !== "" &&
    (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
    (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
const _AIA = "AppInstanceArn";
const _AIUA = "AppInstanceUserArn";
const _CB = "ChimeBearer";
const _CFA = "ChannelFlowArn";
const _MR = "MaxResults";
const _NA = "NotAfter";
const _NB = "NotBefore";
const _NT = "NextToken";
const _P = "Privacy";
const _RARN = "ResourceARN";
const _SCI = "SubChannelId";
const _SO = "SortOrder";
const _T = "Type";
const _a = "arn";
const _aia = "app-instance-arn";
const _aiua = "app-instance-user-arn";
const _cfa = "channel-flow-arn";
const _mr = "max-results";
const _na = "not-after";
const _nb = "not-before";
const _nt = "next-token";
const _o = "operation";
const _p = "privacy";
const _s = "scope";
const _sci = "sub-channel-id";
const _so = "sort-order";
const _t = "type";
const _xacb = "x-amz-chime-bearer";
