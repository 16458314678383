import { get, set } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import Service from '@ember/service';
import joinRequestSoundSrc from '/assets/audio/waiting.mp3';
import joinSoundSrc from '/assets/audio/join.mp3';
import leaveSoundSrc from '/assets/audio/leave.mp3';

export default class SoundNotificationService extends Service {
  joinSound = new Audio(joinSoundSrc);
  leaveSound = new Audio(leaveSoundSrc);
  joinRequestSound = new Audio(joinRequestSoundSrc);
  notificationsAllowed = false;
  notificationsDelayAfterJoin = 2000;

  playJoinSound() {
    this._playSound('joinSound');
  }

  playLeaveSound() {
    this._playSound('leaveSound');
  }

  playJoinRequestSound() {
    this._playSound('joinRequestSound', { ignoreDelay: true });
  }

  async _playSound(soundName, { ignoreDelay } = {}) {
    if (!this.notificationsAllowed && !ignoreDelay) {
      return;
    }
    try {
      await this._playTask.perform(soundName);
    } catch (_err) {
      // sometimes user device is not available to play soud
    }
  }

  @(task(function* (soundName) {
    let sound = get(this, soundName);
    yield sound.play();
    yield timeout(sound.duration);
    set(sound, 'currentTime', 0);
  }).enqueue())
  _playTask;

  @(task(function* () {
    yield timeout(this.notificationsDelayAfterJoin);
    set(this, 'notificationsAllowed', true);
  }).restartable())
  notificationDelayTask;

  handleStart() {
    this.notificationDelayTask.perform();
    this.joinSound.load();
    this.leaveSound.load();
    this.joinRequestSound.load();
  }

  resetDelay() {
    set(this, 'notificationsAllowed', false);
    this._playTask.cancelAll();
  }
}
