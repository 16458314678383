import { tracked } from '@glimmer/tracking';
import { trimmedName } from 'frontend/utils/name-utils';

export default class Attendee {
  @tracked audioSignalScore;
  @tracked videoSignalScore;

  constructor(data) {
    this.email = data.email;
    this.id = data.id;
    this.name = data.name;
    this.profileImageUrl = data.profileImageUrl;
    this.logoColor = data.logoColor;
    this.externalUserId = data.externalUserId;
  }

  update(data) {
    for (let key in data) {
      this[key] = data[key];
    }
  }

  get trimmedName() {
    return trimmedName(this.name);
  }

  get networkLevelScore() {
    return Math.round((this.audioSignalScore + this.videoSignalScore) / 2);
  }

  get hasPoorConnection() {
    return this.networkLevelScore < 3;
  }
}
