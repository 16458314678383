/* import __COLOCATED_TEMPLATE__ from './welcome-screen.hbs'; */
import { NETWORK_QUALITY_TIMEOUT } from 'frontend/constants/twilio';
import { action, computed, set } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { modifier } from 'ember-modifier';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@ember/component';
import ENV from 'frontend/config/environment';
import classic from 'ember-classic-decorator';
import styles from './welcome-screen.module.scss';

const TESTING_TIMEOUT = 1_000;

@classic
@classNames(styles.component)
export default class WelcomeScreen extends Component {
  @service('twilio/room') twilioRoom;
  @service('twilio/video-stream') videoStream;
  @service('chime.meeting-manager') meetingManager;
  @service('chime.readiness-check') readinessCheck;
  @service appointment;
  @service session;
  @service mediaDevices;
  @service mediaSettings;
  @service router;
  @service audioVideoAdapter;

  permissionsResolved = false;
  isDeniedDevicePermissions = false;
  isRoomDisconnected = false;
  videoWrapperContainer = null;

  @reads('twilioRoom.localParticipant') localParticipant;
  @reads('twilioRoom.localParticipant.reconnecting') reconnecting;
  @reads('twilioRoom.connectionError') connectionError;
  @reads('twilioRoom.localNetworkQualityLevel') twilioNetworkQualityLevel;
  @reads('twilioRoom.roomModel.featureTwilioPeerToPeer') featureTwilioPeerToPeer;
  @reads('readinessCheck.networkQualityScore') chimeNetworkQualityLevel;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('audioVideoAdapter.isVideoPublished') isVideoPublished;
  @reads('audioVideoAdapter.isAudioPublished') isAudioPublished;
  @reads('audioVideoAdapter.isMicrophoneAvailable') isMicrophoneAvailable;
  @reads('audioVideoAdapter.isCameraAvailable') isCameraAvailable;

  @computed('featureTwilioPeerToPeer', 'isRoomDisconnected', 'featureThChime')
  get hideQualityIndicator() {
    return this.isRoomDisconnected || this.featureTwilioPeerToPeer;
  }

  @computed('featureThChime', 'chimeNetworkQualityLevel', 'twilioNetworkQualityLevel')
  get networkQualityLevel() {
    if (this.featureThChime) return this.chimeNetworkQualityLevel;
    return this.twilioNetworkQualityLevel;
  }

  @computed('permissionsResolved', 'twilioRoom.room', 'featureThChime')
  get submitDisabled() {
    if (!this.permissionsResolved) return true;
    return !(this.twilioRoom.room || this.featureThChime);
  }

  initTestTwilioRoom = task(async () => {
    try {
      await this.twilioRoom.joinTestRoom({ isWelcomeScreen: true });

      if (!ENV.skipTasksSleep) {
        await timeout(isEmberTesting() ? TESTING_TIMEOUT : NETWORK_QUALITY_TIMEOUT);
        this.twilioRoom.room.disconnect();
        set(this, 'isRoomDisconnected', true);
      }
    } catch (error) {
      set(this, 'isDeniedDevicePermissions', true);
      if (error.name !== 'NotAllowedError') {
        window._bugsnagClient?.notify(error);
      }
    }
  });

  @action
  toggleLocalAudio() {
    return this.audioVideoAdapter.toggleLocalAudio();
  }

  @action
  toggleLocalVideo() {
    this.audioVideoAdapter.toggleLocalVideo();
  }

  @action
  openSettings() {
    this.mediaSettings.openSettingsModal();
  }

  @action
  resetConnectionError() {
    this.twilioRoom.setConnectionError(null);
  }

  @action
  async joinCall() {
    if (this.featureThChime && this.session.isHost) {
      this.readinessCheck.stop();
      await this.meetingManager.leave();
    } else {
      this.twilioRoom.leaveTestRoom();
    }

    return this.router.transitionTo('appointment.room', this.session.roomModel.roomId);
  }

  onWelcomeScreenInsert = modifier(async element => {
    await waitForNext();
    set(this, 'videoWrapperContainer', element.parentElement);

    try {
      let devices = await this.mediaDevices.enumerateDevices();
      await this.mediaDevices.takeDevicesControl(devices);
      set(this, 'permissionsResolved', true);

      if (this.featureThChime) {
        await this.appointment.initSession({ isTest: true });
        this.readinessCheck.start();
      } else {
        this.initTestTwilioRoom.perform();
      }
    } catch (e) {
      set(this, 'isDeniedDevicePermissions', true);
    }
  });
}
