import { action, set } from '@ember/object';
import { alias, reads } from '@ember/object/computed';
import { fetchRoomModelData, fetchUserData } from 'frontend/utils/fetch-data';
import { isMobileOrTablet, isTablet } from 'frontend/utils/detect-browser';
import { redirectToApp } from 'frontend/utils/app-link';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

@classic
export default class AppointmentRoute extends Route {
  @service errorHandling;
  @service mediaDevices;
  @service mixpanel;
  @service persistentProperties;
  @service session;
  @service dataFetcher;
  @service router;
  @service appointment;

  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @alias('session.inWaitingRoom') inWaitingRoom;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    let { room_id: roomId } = this.paramsFor('appointment');

    this.checkMobileDevice(transition);

    let isBrowserSupported = !!navigator.mediaDevices?.getUserMedia;
    if (!isBrowserSupported) {
      this.router.transitionTo('unsupported-browser');
    }
    if (!/appt-[\s\w]{32}$/.test(roomId)) {
      this.router.transitionTo('error.invalid-appointment');
    }
  }

  async model({ room_id: roomId, origin }) {
    if (!this.persistentProperties.uuid) {
      set(this.persistentProperties, 'uuid', generateUUID());
    }

    let fetchTasks = [
      this.dataFetcher.fetchWithRetry.perform(() =>
        fetchRoomModelData(roomId, this.persistentProperties.uuid)
      ),
      this.dataFetcher.fetchWithRetry.perform(fetchUserData),
    ];
    let [{ error, roomModel }, userData] = await Promise.all(fetchTasks).catch(async error => {
      await this.errorHandling.connectRoomError(error);
      location.reload();
    });

    if (error) throw error;

    if (roomModel.featureThChime) {
      this.appointment.setup();
    }

    let model = { ...roomModel, origin };

    if (userData && model.featureThClinicianAuth) {
      this.session.setUserData(userData);
      model.userData = userData;
    }
    this.session.setInitialData(model);
    this.mixpanel.setInitialData(model);

    return model;
  }

  afterModel() {
    super.afterModel(...arguments);

    this.mediaDevices.trackDevices();
  }

  @action
  error({ errors }, _transition) {
    switch (errors?.[0]?.code) {
      case 101:
        return this.router.transitionTo('error.video-disabled');
      case 102:
      case 103:
        return this.router.transitionTo('error.invalid-appointment');
      case 104:
        return this.router.transitionTo('error.session-creation-failed');
      default:
        return true;
    }
  }

  async checkMobileDevice(transition) {
    let { room_id: roomId, origin } = this.paramsFor('appointment');
    if (isMobileOrTablet()) {
      if (!isTablet() || this.persistentProperties.tabletSelectedOpenType === 'app') {
        await redirectToApp({ roomId, origin });
        transition.abort();
      } else if (
        transition?.from?.name !== 'download-app' &&
        this.persistentProperties.tabletSelectedOpenType !== 'browser'
      ) {
        this.router.transitionTo('download-app', { queryParams: { roomId, origin } });
      }
    }
  }

  @action
  willTransition(transition) {
    if (transition.to.name === 'appointment.index') {
      transition.router.refresh();
    }
  }
}
