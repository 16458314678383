import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { ChimeSDKMessagingServiceException as __BaseException } from "./ChimeSDKMessagingServiceException";
export const AllowNotifications = {
    ALL: "ALL",
    FILTERED: "FILTERED",
    NONE: "NONE",
};
export const ChannelMembershipType = {
    DEFAULT: "DEFAULT",
    HIDDEN: "HIDDEN",
};
export const ErrorCode = {
    AccessDenied: "AccessDenied",
    BadRequest: "BadRequest",
    Conflict: "Conflict",
    Forbidden: "Forbidden",
    NotFound: "NotFound",
    PhoneNumberAssociationsExist: "PhoneNumberAssociationsExist",
    PreconditionFailed: "PreconditionFailed",
    ResourceLimitExceeded: "ResourceLimitExceeded",
    ServiceFailure: "ServiceFailure",
    ServiceUnavailable: "ServiceUnavailable",
    Throttled: "Throttled",
    Throttling: "Throttling",
    Unauthorized: "Unauthorized",
    Unprocessable: "Unprocessable",
    VoiceConnectorGroupAssociationsExist: "VoiceConnectorGroupAssociationsExist",
};
export class BadRequestException extends __BaseException {
    constructor(opts) {
        super({
            name: "BadRequestException",
            $fault: "client",
            ...opts,
        });
        this.name = "BadRequestException";
        this.$fault = "client";
        Object.setPrototypeOf(this, BadRequestException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class ConflictException extends __BaseException {
    constructor(opts) {
        super({
            name: "ConflictException",
            $fault: "client",
            ...opts,
        });
        this.name = "ConflictException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ConflictException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class ForbiddenException extends __BaseException {
    constructor(opts) {
        super({
            name: "ForbiddenException",
            $fault: "client",
            ...opts,
        });
        this.name = "ForbiddenException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ForbiddenException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class NotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "NotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "NotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, NotFoundException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class ServiceFailureException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceFailureException",
            $fault: "server",
            ...opts,
        });
        this.name = "ServiceFailureException";
        this.$fault = "server";
        Object.setPrototypeOf(this, ServiceFailureException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class ServiceUnavailableException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceUnavailableException",
            $fault: "server",
            ...opts,
        });
        this.name = "ServiceUnavailableException";
        this.$fault = "server";
        Object.setPrototypeOf(this, ServiceUnavailableException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class ThrottledClientException extends __BaseException {
    constructor(opts) {
        super({
            name: "ThrottledClientException",
            $fault: "client",
            ...opts,
        });
        this.name = "ThrottledClientException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ThrottledClientException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class UnauthorizedClientException extends __BaseException {
    constructor(opts) {
        super({
            name: "UnauthorizedClientException",
            $fault: "client",
            ...opts,
        });
        this.name = "UnauthorizedClientException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UnauthorizedClientException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export class ResourceLimitExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceLimitExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceLimitExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceLimitExceededException.prototype);
        this.Code = opts.Code;
        this.Message = opts.Message;
    }
}
export const ExpirationCriterion = {
    CREATED_TIMESTAMP: "CREATED_TIMESTAMP",
    LAST_MESSAGE_TIMESTAMP: "LAST_MESSAGE_TIMESTAMP",
};
export const ChannelMode = {
    RESTRICTED: "RESTRICTED",
    UNRESTRICTED: "UNRESTRICTED",
};
export const ChannelPrivacy = {
    PRIVATE: "PRIVATE",
    PUBLIC: "PUBLIC",
};
export const InvocationType = {
    ASYNC: "ASYNC",
};
export const FallbackAction = {
    ABORT: "ABORT",
    CONTINUE: "CONTINUE",
};
export const PushNotificationType = {
    DEFAULT: "DEFAULT",
    VOIP: "VOIP",
};
export const ChannelMessagePersistenceType = {
    NON_PERSISTENT: "NON_PERSISTENT",
    PERSISTENT: "PERSISTENT",
};
export const ChannelMessageStatus = {
    DENIED: "DENIED",
    FAILED: "FAILED",
    PENDING: "PENDING",
    SENT: "SENT",
};
export const ChannelMessageType = {
    CONTROL: "CONTROL",
    STANDARD: "STANDARD",
};
export const MessagingDataType = {
    Channel: "Channel",
    ChannelMessage: "ChannelMessage",
};
export const SortOrder = {
    ASCENDING: "ASCENDING",
    DESCENDING: "DESCENDING",
};
export const SearchFieldKey = {
    MEMBERS: "MEMBERS",
};
export const SearchFieldOperator = {
    EQUALS: "EQUALS",
    INCLUDES: "INCLUDES",
};
export const IdentityFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
});
export const BatchChannelMembershipsFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.InvitedBy && { InvitedBy: IdentityFilterSensitiveLog(obj.InvitedBy) }),
    ...(obj.Members && { Members: obj.Members.map((item) => IdentityFilterSensitiveLog(item)) }),
});
export const BatchCreateChannelMembershipResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.BatchChannelMemberships && {
        BatchChannelMemberships: BatchChannelMembershipsFilterSensitiveLog(obj.BatchChannelMemberships),
    }),
});
export const ChannelFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.CreatedBy && { CreatedBy: IdentityFilterSensitiveLog(obj.CreatedBy) }),
});
export const ChannelAssociatedWithFlowSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
});
export const ChannelBanFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
    ...(obj.CreatedBy && { CreatedBy: IdentityFilterSensitiveLog(obj.CreatedBy) }),
});
export const ChannelBanSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
});
export const ProcessorFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
});
export const ChannelFlowFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Processors && { Processors: obj.Processors.map((item) => ProcessorFilterSensitiveLog(item)) }),
    ...(obj.Name && { Name: SENSITIVE_STRING }),
});
export const MessageAttributeValueFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.StringValues && { StringValues: SENSITIVE_STRING }),
});
export const PushNotificationConfigurationFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Title && { Title: SENSITIVE_STRING }),
    ...(obj.Body && { Body: SENSITIVE_STRING }),
});
export const ChannelMessageCallbackFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Content && { Content: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.PushNotification && {
        PushNotification: PushNotificationConfigurationFilterSensitiveLog(obj.PushNotification),
    }),
    ...(obj.MessageAttributes && {
        MessageAttributes: Object.entries(obj.MessageAttributes).reduce((acc, [key, value]) => ((acc[key] = MessageAttributeValueFilterSensitiveLog(value)), acc), {}),
    }),
    ...(obj.ContentType && { ContentType: SENSITIVE_STRING }),
});
export const ChannelFlowCallbackRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelMessage && { ChannelMessage: ChannelMessageCallbackFilterSensitiveLog(obj.ChannelMessage) }),
});
export const ChannelFlowSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Processors && { Processors: obj.Processors.map((item) => ProcessorFilterSensitiveLog(item)) }),
});
export const ChannelMembershipFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.InvitedBy && { InvitedBy: IdentityFilterSensitiveLog(obj.InvitedBy) }),
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
});
export const ChannelSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
});
export const ChannelMembershipForAppInstanceUserSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelSummary && { ChannelSummary: ChannelSummaryFilterSensitiveLog(obj.ChannelSummary) }),
});
export const PushNotificationPreferencesFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.FilterRule && { FilterRule: SENSITIVE_STRING }),
});
export const ChannelMembershipPreferencesFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.PushNotifications && {
        PushNotifications: PushNotificationPreferencesFilterSensitiveLog(obj.PushNotifications),
    }),
});
export const ChannelMembershipSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
});
export const ChannelMessageFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Content && { Content: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.Sender && { Sender: IdentityFilterSensitiveLog(obj.Sender) }),
    ...(obj.MessageAttributes && {
        MessageAttributes: Object.entries(obj.MessageAttributes).reduce((acc, [key, value]) => ((acc[key] = MessageAttributeValueFilterSensitiveLog(value)), acc), {}),
    }),
    ...(obj.ContentType && { ContentType: SENSITIVE_STRING }),
});
export const ChannelMessageSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Content && { Content: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.Sender && { Sender: IdentityFilterSensitiveLog(obj.Sender) }),
    ...(obj.MessageAttributes && {
        MessageAttributes: Object.entries(obj.MessageAttributes).reduce((acc, [key, value]) => ((acc[key] = MessageAttributeValueFilterSensitiveLog(value)), acc), {}),
    }),
    ...(obj.ContentType && { ContentType: SENSITIVE_STRING }),
});
export const ChannelModeratedByAppInstanceUserSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelSummary && { ChannelSummary: ChannelSummaryFilterSensitiveLog(obj.ChannelSummary) }),
});
export const ChannelModeratorFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Moderator && { Moderator: IdentityFilterSensitiveLog(obj.Moderator) }),
    ...(obj.CreatedBy && { CreatedBy: IdentityFilterSensitiveLog(obj.CreatedBy) }),
});
export const ChannelModeratorSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Moderator && { Moderator: IdentityFilterSensitiveLog(obj.Moderator) }),
});
export const TagFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
    ...(obj.Value && { Value: SENSITIVE_STRING }),
});
export const CreateChannelRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.ClientRequestToken && { ClientRequestToken: SENSITIVE_STRING }),
    ...(obj.Tags && { Tags: obj.Tags.map((item) => TagFilterSensitiveLog(item)) }),
    ...(obj.ChannelId && { ChannelId: SENSITIVE_STRING }),
});
export const CreateChannelBanResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
});
export const CreateChannelFlowRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Processors && { Processors: obj.Processors.map((item) => ProcessorFilterSensitiveLog(item)) }),
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Tags && { Tags: obj.Tags.map((item) => TagFilterSensitiveLog(item)) }),
    ...(obj.ClientRequestToken && { ClientRequestToken: SENSITIVE_STRING }),
});
export const CreateChannelMembershipResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
});
export const CreateChannelModeratorResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelModerator && { ChannelModerator: IdentityFilterSensitiveLog(obj.ChannelModerator) }),
});
export const DescribeChannelResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Channel && { Channel: ChannelFilterSensitiveLog(obj.Channel) }),
});
export const DescribeChannelBanResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelBan && { ChannelBan: ChannelBanFilterSensitiveLog(obj.ChannelBan) }),
});
export const DescribeChannelFlowResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelFlow && { ChannelFlow: ChannelFlowFilterSensitiveLog(obj.ChannelFlow) }),
});
export const DescribeChannelMembershipResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelMembership && { ChannelMembership: ChannelMembershipFilterSensitiveLog(obj.ChannelMembership) }),
});
export const DescribeChannelMembershipForAppInstanceUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelMembership && {
        ChannelMembership: ChannelMembershipForAppInstanceUserSummaryFilterSensitiveLog(obj.ChannelMembership),
    }),
});
export const DescribeChannelModeratedByAppInstanceUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Channel && { Channel: ChannelModeratedByAppInstanceUserSummaryFilterSensitiveLog(obj.Channel) }),
});
export const DescribeChannelModeratorResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelModerator && { ChannelModerator: ChannelModeratorFilterSensitiveLog(obj.ChannelModerator) }),
});
export const GetChannelMembershipPreferencesResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
    ...(obj.Preferences && { Preferences: ChannelMembershipPreferencesFilterSensitiveLog(obj.Preferences) }),
});
export const GetChannelMessageResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelMessage && { ChannelMessage: ChannelMessageFilterSensitiveLog(obj.ChannelMessage) }),
});
export const ListChannelBansRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelBansResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
    ...(obj.ChannelBans && { ChannelBans: obj.ChannelBans.map((item) => ChannelBanSummaryFilterSensitiveLog(item)) }),
});
export const ListChannelFlowsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelFlowsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelFlows && { ChannelFlows: obj.ChannelFlows.map((item) => ChannelFlowSummaryFilterSensitiveLog(item)) }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelMembershipsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelMembershipsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelMemberships && {
        ChannelMemberships: obj.ChannelMemberships.map((item) => ChannelMembershipSummaryFilterSensitiveLog(item)),
    }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelMembershipsForAppInstanceUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelMembershipsForAppInstanceUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ChannelMemberships && {
        ChannelMemberships: obj.ChannelMemberships.map((item) => ChannelMembershipForAppInstanceUserSummaryFilterSensitiveLog(item)),
    }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelMessagesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelMessagesResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
    ...(obj.ChannelMessages && {
        ChannelMessages: obj.ChannelMessages.map((item) => ChannelMessageSummaryFilterSensitiveLog(item)),
    }),
});
export const ListChannelModeratorsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelModeratorsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
    ...(obj.ChannelModerators && {
        ChannelModerators: obj.ChannelModerators.map((item) => ChannelModeratorSummaryFilterSensitiveLog(item)),
    }),
});
export const ListChannelsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Channels && { Channels: obj.Channels.map((item) => ChannelSummaryFilterSensitiveLog(item)) }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelsAssociatedWithChannelFlowRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelsAssociatedWithChannelFlowResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Channels && {
        Channels: obj.Channels.map((item) => ChannelAssociatedWithFlowSummaryFilterSensitiveLog(item)),
    }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelsModeratedByAppInstanceUserRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListChannelsModeratedByAppInstanceUserResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Channels && {
        Channels: obj.Channels.map((item) => ChannelModeratedByAppInstanceUserSummaryFilterSensitiveLog(item)),
    }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListSubChannelsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListSubChannelsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const ListTagsForResourceResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Tags && { Tags: obj.Tags.map((item) => TagFilterSensitiveLog(item)) }),
});
export const PutChannelMembershipPreferencesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Preferences && { Preferences: ChannelMembershipPreferencesFilterSensitiveLog(obj.Preferences) }),
});
export const PutChannelMembershipPreferencesResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Member && { Member: IdentityFilterSensitiveLog(obj.Member) }),
    ...(obj.Preferences && { Preferences: ChannelMembershipPreferencesFilterSensitiveLog(obj.Preferences) }),
});
export const SearchChannelsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const SearchChannelsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Channels && { Channels: obj.Channels.map((item) => ChannelSummaryFilterSensitiveLog(item)) }),
    ...(obj.NextToken && { NextToken: SENSITIVE_STRING }),
});
export const SendChannelMessageRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Content && { Content: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.ClientRequestToken && { ClientRequestToken: SENSITIVE_STRING }),
    ...(obj.PushNotification && {
        PushNotification: PushNotificationConfigurationFilterSensitiveLog(obj.PushNotification),
    }),
    ...(obj.MessageAttributes && {
        MessageAttributes: Object.entries(obj.MessageAttributes).reduce((acc, [key, value]) => ((acc[key] = MessageAttributeValueFilterSensitiveLog(value)), acc), {}),
    }),
    ...(obj.ContentType && { ContentType: SENSITIVE_STRING }),
});
export const TagResourceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Tags && { Tags: obj.Tags.map((item) => TagFilterSensitiveLog(item)) }),
});
export const UntagResourceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.TagKeys && { TagKeys: SENSITIVE_STRING }),
});
export const UpdateChannelRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Name && { Name: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
});
export const UpdateChannelFlowRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Processors && { Processors: obj.Processors.map((item) => ProcessorFilterSensitiveLog(item)) }),
    ...(obj.Name && { Name: SENSITIVE_STRING }),
});
export const UpdateChannelMessageRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Content && { Content: SENSITIVE_STRING }),
    ...(obj.Metadata && { Metadata: SENSITIVE_STRING }),
    ...(obj.ContentType && { ContentType: SENSITIVE_STRING }),
});
