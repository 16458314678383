import { template } from "@ember/template-compiler";
import { NETWORK_QUALITY_SCORE } from 'frontend/utils/network-connection';
import { action } from '@ember/object';
import { and, reads } from 'macro-decorators';
import { cached, tracked } from '@glimmer/tracking';
import { concat } from '@ember/helper';
import { initials, trimmedName } from 'frontend/utils/name-utils';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import { service } from '@ember/service';
import AttendeeTile from 'frontend/components/call/attendee-tile';
import Component from '@glimmer/component';
import SharedDropdownPopover from 'frontend/components/shared/dropdown/popover';
import styles from './speaker.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallSpeaker extends Component {
    @service('chime.meeting-manager')
    meetingManager;
    @service('chime.remote-videos')
    remoteVideos;
    @service('chime.roster')
    rosterService;
    @service('chime.content-sharing')
    contentSharing;
    @service
    persistentProperties;
    @service
    appointmentSettings;
    @service
    audioVideoAdapter;
    @service
    appointment;
    @service
    uiElements;
    @service
    session;
    @tracked
    morePopoverShown = false;
    @tracked
    isVideoStalled = false;
    #cachedTileId = null;
    @reads('appointmentSettings.waitingRoomEnabled')
    waitingRoomEnabled;
    @reads('appointment.localTileId')
    localTileId;
    @reads('contentSharing.isWhiteboardShared')
    isWhiteboardShared;
    @reads('uiElements.pinnedAttendeeId')
    pinnedAttendeeId;
    @reads('uiElements.isGridView')
    isGridView;
    @reads('args.attendeeId')
    attendeeId;
    @reads('args.isMainView')
    isInMainView;
    @reads('session.isHost')
    isHost;
    @reads('rosterService.activeSpeakerId')
    activeSpeakerId;
    @reads('videoTileId')
    hasVideo;
    @and('persistentProperties.isSelfViewMirrored', 'isLocal')
    isMirrored;
    get networkLevel() {
        if (this.isReconnecting) return NETWORK_QUALITY_SCORE.noConnection;
        if (this.speakerData?.audioSignalScore === undefined) return undefined;
        if (!this.hasVideo) {
            return this.speakerData.audioSignalScore;
        }
        return this.speakerData.networkLevelScore;
    }
    get isReconnecting() {
        return !!this.speakerData?.reconnecting;
    }
    get isMuted() {
        return !!this.speakerData?.muted;
    }
    get isLocal() {
        return this.args.attendeeId === this.meetingManager.localAttendeeId;
    }
    @cached
    get speakerData() {
        return this.rosterService.getAttendee(this.attendeeId);
    }
    get name() {
        return this.speakerData?.name;
    }
    get logoColor() {
        return this.speakerData?.logoColor;
    }
    get trimmedName() {
        return trimmedName(this.name || '');
    }
    get initials() {
        return initials(this.name || '');
    }
    get isPinned() {
        return this.pinnedAttendeeId === this.attendeeId;
    }
    get isPinVisible() {
        if (this.isGridView || this.isWhiteboardShared) return false;
        return this.isPinned || !this.waitingRoomEnabled || !this.isHost || this.isLocal;
    }
    get pinButtonClass() {
        return [
            'pin-button',
            this.isPinned && 'pinned',
            this.isInMainView && 'main-view',
            this.isPinned && this.isMoreButtonVisible && 'shifted'
        ].filter(Boolean).join(' ');
    }
    get isMoreButtonVisible() {
        return this.isHost && !this.isLocal && this.waitingRoomEnabled;
    }
    get isPinOptionShown() {
        return !(this.isGridView || this.isWhiteboardShared);
    }
    get moreButtonOptions() {
        let showSendToWaitingRoomOption1 = this.isHost && !this.isLocal;
        return [
            this.isPinOptionShown && {
                title: this.isPinned ? 'Unpin' : 'Pin',
                onClick: this.handlePin
            },
            showSendToWaitingRoomOption1 && {
                title: 'Send to waiting room',
                onClick: this.handleSendToWaitingRoom
            }
        ].filter(Boolean);
    }
    get moreButtonClasses() {
        return [
            'more-button',
            !this.isMoreButtonVisible && 'hidden',
            this.morePopoverShown && 'active'
        ].filter(Boolean).join(' ');
    }
    get isHighlighted() {
        if (this.isMuted || this.isInMainView || !(this.pinnedAttendeeId || this.isGridView)) {
            return false;
        }
        return this.activeSpeakerId === this.attendeeId;
    }
    @action
    handlePin() {
        this.uiElements.handlePin(this.attendeeId);
    }
    @action
    handleSendToWaitingRoom() {
        this.appointment.sendToWaitingRoom(this.attendeeId);
    }
    @action
    toggleMorePopover() {
        this.morePopoverShown = !this.morePopoverShown;
    }
    @action
    setVideoStalledState(state1) {
        this.isVideoStalled = state1;
    }
    get videoTileId() {
        return this.isLocal ? this.localTileId : this.remoteVideos.attendeeIdToTileId[this.attendeeId];
    }
    initVideo = modifier((videoElement1)=>{
        if (this.videoTileId === this.#cachedTileId) return;
        this.#cachedTileId = this.videoTileId;
        this.audioVideoAdapter.attachVideo({
            videoElement: videoElement1,
            tileId: this.videoTileId
        });
    });
    static{
        template(`
    <div
      class={{concat
        styles.component
        (if this.isHighlighted " highlighted")
        (if this.isMirrored " mirror-video")
      }}
      data-test-speaker-container={{if this.isLocal "local" this.attendeeId}}
      ...attributes
    >
      {{#if this.isPinVisible}}
        <button
          type="button"
          class={{this.pinButtonClass}}
          {{on "click" this.handlePin}}
          data-test-pin-button
        />
      {{/if}}
      <button
        type="button"
        class={{this.moreButtonClasses}}
        data-test-more-button={{or this.index "publisher"}}
      >
        {{svgJar "dots-more"}}
        <SharedDropdownPopover
          @dropdownOptions={{this.moreButtonOptions}}
          @onChange={{this.toggleMorePopover}}
        />
      </button>

      <AttendeeTile
        @hasVideo={{this.hasVideo}}
        @initContainerModifier={{this.initSpeaker}}
        @initVideoModifier={{this.initVideo}}
        @initials={{this.initials}}
        @logoColor={{this.logoColor}}
        @muted={{this.isMuted}}
        @profileImageUrl={{this.profileImageUrl}}
        @trimmedName={{this.trimmedName}}
        @networkLevel={{this.networkLevel}}
        @stalled={{this.isVideoStalled}}
        @reconnecting={{this.isReconnecting}}
        @mainView={{this.isInMainView}}
        @setVideoStalledState={{this.setVideoStalledState}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
