import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import AttachTooltip from 'ember-attacher/components/attach-tooltip';
import CallSidebarRoster from 'frontend/components/call/sidebar/roster';
import Component from '@glimmer/component';
import styles from './panel.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallSidebarPanel extends Component {
    @service('chime.content-sharing')
    contentSharing;
    @service
    appointment;
    @service
    uiElements;
    @reads('uiElements.isSidebarShown')
    isExpanded;
    @reads('uiElements.isSelfViewShown')
    isSelfViewShown;
    @reads('appointment.shareParticipant')
    shareParticipant;
    @reads('appointment.rosterSize')
    rosterSize;
    get isHidden() {
        if (this.isSelfViewShown) return false;
        if (this.rosterSize === 0) return true;
        let sidebarPanelTilesShown1 = this.rosterSize - 1;
        if (this.contentSharing.sharedEntity) {
            sidebarPanelTilesShown1 += 1;
        }
        return sidebarPanelTilesShown1 === 0;
    }
    @action
    togglePanelShown() {
        this.uiElements.toggleSidebar();
    }
    initSidebar = modifier((element1)=>{
        this.args?.onSidebarInsert(element1);
    });
    static{
        template(`
    <div
      class={{concat
        styles.component
        (unless this.isExpanded " collapsed")
        (if this.isHidden " hidden")
      }}
      {{this.initSidebar}}
      ...attributes
    >
      <div class="action-button-container {{unless @controlsShown 'fadeout'}}">
        <button
          class="action-button {{if this.isExpanded 'expanded'}}"
          {{on "click" this.togglePanelShown}}
          type="button"
          data-test-toggle-sidebar
        >
          {{svgJar "chevron-right-long" class=(unless this.isExpanded "mirrored")}}
          <AttachTooltip @placement="left" @class="tooltip {{unless @controlsShown 'hidden'}}">
            {{if this.isExpanded "Hide participants" "Show participants"}}
          </AttachTooltip>
        </button>
      </div>
      <CallSidebarRoster />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
