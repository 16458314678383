/* import __COLOCATED_TEMPLATE__ from './user-form.hbs'; */
import { action, computed, set } from '@ember/object';
import { classNames, tagName } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './user-form.module.scss';

@classic
@tagName('form')
@classNames(styles.component)
export default class UserForm extends Component {
  @service session;

  nameMaxLength = 70;
  isNameDirty = false;

  @reads('session.userName') userName;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('session.isHost') isHost;

  @computed('userName')
  get errorMessage() {
    let nameLength = this.userName?.trim().length;
    if (nameLength === 0 || nameLength === undefined) {
      return '* field required';
    } else if (nameLength > this.nameMaxLength) {
      return `* name is too long (maximum is ${this.nameMaxLength} characters)`;
    }
    return null;
  }

  @action
  setUserName(userName) {
    this.session.setUserName(userName);
  }

  @action
  submit(event) {
    event.preventDefault();
    this.setUserName(this.userName?.trim());
    set(this, 'isNameDirty', true);

    if (!this.errorMessage) {
      this.submitForm?.();
    }
  }
}
