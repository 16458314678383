/* import __COLOCATED_TEMPLATE__ from './test-sound.hbs'; */
import { action } from '@ember/object';
import { classNames, tagName } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import pianoSoundSrc from '/assets/audio/piano.mp3';
import styles from './test-sound.module.scss';

@classic
@tagName('button')
@classNames(styles.component)
export default class TestSound extends Component {
  testAudio = new Audio(pianoSoundSrc);
  playingTest = false;
  sinkId;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.testAudio.addEventListener('ended', () => {
      this.set('playingTest', false);
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.testAudio.pause();
    this.testAudio.currentTime = 0;
  }

  // eslint-disable-next-line ember/no-observers
  @observes('sinkId')
  handleSinkChange() {
    this.testAudio.setSinkId?.(this.sinkId);
  }

  @action
  click() {
    this.toggleProperty('playingTest');
    this.playingTest ? this.testAudio.play() : this.testAudio.pause();
  }
}
