import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { ListChannelBansRequestFilterSensitiveLog, ListChannelBansResponseFilterSensitiveLog, } from "../models/models_0";
import { de_ListChannelBansCommand, se_ListChannelBansCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class ListChannelBansCommand extends $Command
    .classBuilder()
    .ep({
    ...commonParams,
})
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "ListChannelBans", {})
    .n("ChimeSDKMessagingClient", "ListChannelBansCommand")
    .f(ListChannelBansRequestFilterSensitiveLog, ListChannelBansResponseFilterSensitiveLog)
    .ser(se_ListChannelBansCommand)
    .de(de_ListChannelBansCommand)
    .build() {
}
