import { action } from '@ember/object';
import { keepLatestTask, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import EventLogger from 'frontend/entities/chime/event-logger';
import Service, { service } from '@ember/service';

const METRICS_INTERVAL = 3000;
export const MEETING_EVENTS = {
  meetingStartSucceeded: 'meetingStartSucceeded',
  meetingEnded: 'meetingEnded',
  receivingAudioDropped: 'receivingAudioDropped',
  signalingDropped: 'signalingDropped',
  meetingReconnected: 'meetingReconnected',
  meetingFailed: 'meetingFailed',
  metricsDidReceive: 'metricsDidReceive',
};

export default class ChimeEventManagerService extends Service {
  @service('chime.sdk') sdkService;
  @service('chime.meeting-manager') meetingManager;
  @service('chime.local-audio-video') localAudioVideo;

  logger;

  @reads('sdkService.sdk') chimeSdk;
  @reads('meetingManager.meetingSession') meetingSession;
  @reads('localAudioVideo.tileId') localTileId;

  sendClientMetricsTask = keepLatestTask(async (observableMetrics, videoMetrics) => {
    let attributes = {
      attendeeId: this.localTileId,
      audioPacketLossPercent: observableMetrics.audioPacketLossPercent,
      audioUpstreamJitterMs: observableMetrics.audioUpstreamJitterMs,
      audioUpstreamRoundTripTimeMs: observableMetrics.audioUpstreamRoundTripTimeMs,
    };

    if (videoMetrics?.videoUpstreamBitrate) {
      attributes.videoUpstreamBitrate = videoMetrics.videoUpstreamBitrate;
      attributes.videoUpstreamJitterMs = videoMetrics.videoUpstreamJitterMs;
      attributes.videoUpstreamPacketLossPercent = videoMetrics.videoUpstreamPacketLossPercent;
      attributes.videoUpstreamRoundTripTimeMs = videoMetrics.videoUpstreamRoundTripTimeMs;
    }

    this.logger.log(MEETING_EVENTS.metricsDidReceive, attributes);
    await timeout(METRICS_INTERVAL);
  });

  setup() {
    let { eventController, audioVideo } = this.meetingSession;

    this.metricsObserver = { metricsDidReceive: this.metricsDidReceive };
    this.eventsObesrver = { eventDidReceive: this.eventDidReceive };
    this.logger = new EventLogger(this.chimeSdk);

    eventController.addObserver(this.eventsObesrver);
    audioVideo.addObserver(this.metricsObserver);
  }

  @action
  metricsDidReceive(clientMetricReport) {
    let observableMetrics = clientMetricReport.getObservableMetrics();
    let observableVideoMetrics = clientMetricReport.getObservableVideoMetrics();
    let metricsMap = observableVideoMetrics[this.meetingManager.localAttendeeId] || {};
    let [localVideoMetrics] = Object.values(metricsMap);

    this.sendClientMetricsTask.perform(observableMetrics, localVideoMetrics);
  }

  @action
  eventDidReceive(name, attrs) {
    let { meetingHistory, ...attributes } = attrs;

    switch (name) {
      case MEETING_EVENTS.meetingStartSucceeded:
        this.logger.log(name, attributes);
        break;
      case MEETING_EVENTS.meetingEnded:
        this.logger.log(name, {
          attendeeId: attributes.attendeeId,
          timestampMs: attributes.timestampMs,
        });
        this.logger.destroy();
        break;
    }
  }

  reset() {
    this.meetingSession?.eventController?.removeObserver(this.eventsObesrver);
    this.meetingSession?.audioVideo?.removeObserver(this.metricsObserver);
  }
}
