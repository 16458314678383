import { SHAREABLE_ENTITIES, TRACK_TYPE } from 'frontend/constants';
import { action, computed } from '@ember/object';
import { parseTrackInfo } from 'frontend/utils/name-utils';
import { reads } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class AudioVideoAdapterService extends Service {
  @service('twilio.room') twilioRoom;
  @service('twilio/video-stream') videoStream;
  @service('chime.meeting-manager') meetingManager;
  @service('chime.local-audio-video') localAudioVideo;
  @service('chime.remote-videos') remoteVideos;
  @service('chime.content-sharing') contentSharing;
  @service('chime.roster') roster;
  @service persistentProperties;
  @service mixpanel;
  @service session;
  @service appointment;

  @reads('twilioRoom.audioTrack') twilioAudioTrack;
  @reads('twilioRoom.videoTrack') twilioVideoTrack;
  @reads('persistentProperties.userName') userName;
  @reads('persistentProperties.selectedVideoDeviceId') selectedVideoDeviceId;
  @reads('persistentProperties.selectedAudioOutputDeviceId') selectedAudioOutputDeviceId;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('persistentProperties.isVideoPublished') isVideoPublished;
  @reads('persistentProperties.isAudioPublished') isAudioPublished;

  @computed('twilioRoom.localParticipant')
  get localParticipant() {
    return this.twilioRoom.localParticipant;
  }

  @computed('twilioRoom.shareParticipant', 'contentSharing.shareAttendeeId')
  get shareParticipant() {
    return this.featureThChime
      ? this.roster.getAttendee(this.contentSharing.shareAttendeeId)
      : this.twilioRoom.shareParticipant;
  }

  @computed('shareParticipant.tracks')
  get screenShareAudioSource() {
    if (this.featureThChime) return null;

    return this.getTrack({
      participant: this.shareParticipant,
      trackType: TRACK_TYPE.screenAudio,
    });
  }

  @computed('shareParticipant.tracks', 'shareParticipant')
  get screenShareVideoSource() {
    return this.getTrack({
      participant: this.shareParticipant,
      trackType: TRACK_TYPE.screensharing,
    });
  }

  @computed('twilioAudioTrack', 'featureThChime', 'meetingManager.audioVideo')
  get isMicrophoneAvailable() {
    if (this.featureThChime) return !!this.meetingManager.audioVideo;

    return !!this.twilioAudioTrack;
  }

  @computed('featureThChime', 'meetingManager.audioVideo')
  get isCameraAvailable() {
    return this.featureThChime ? !!this.meetingManager.audioVideo : true;
  }

  @computed('featureThChime', 'localAudioVideo.videoInput', 'twilioVideoTrack')
  get videoInputSource() {
    return this.featureThChime ? this.localAudioVideo.videoInput : this.twilioVideoTrack;
  }

  @computed('videoInputSource')
  get hasLocalCameraSource() {
    return !!this.videoInputSource;
  }

  get isBackgroundSupported() {
    return this.featureThChime
      ? this.localAudioVideo.isVideoProcessingSupported
      : this.twilioRoom.processorsSupported;
  }

  get sharedEntity() {
    return this.featureThChime ? this.contentSharing.sharedEntity : this.twilioRoom.sharedEntity;
  }

  get isSharing() {
    return this.featureThChime ? this.contentSharing.isSharing : this.twilioRoom.isSharing;
  }

  get isSharingScreen() {
    return this.featureThChime
      ? this.contentSharing.isSharingScreen
      : this.twilioRoom.isSharingScreen;
  }

  get isWhiteboarding() {
    return this.featureThChime
      ? this.contentSharing.isWhiteboardShared
      : this.twilioRoom.isWhiteboarding;
  }

  @action
  handleEnterPictureInPicture() {
    this.mixpanel.track('enter picture in picture', { status: 'enabled' });
  }

  @action
  handleLeavePictureInPicture(event) {
    event.target.play();
    this.mixpanel.track('enter picture in picture', { status: 'disabled' });
  }

  attachVideo({ videoElement, tileId }) {
    if (!this.featureThChime) throw new Error('This method is not supported for Twilio');

    this.meetingManager.audioVideo?.bindVideoElement(tileId, videoElement);
  }

  getTrack({ participant, trackType }) {
    if (!participant) return null;

    return Array.from(participant.tracks.values()).find(publication => {
      if (!publication.track) return;
      let { type } = publication.trackInfo || parseTrackInfo(publication.trackName);

      return type === trackType;
    })?.track;
  }

  async stopLocalVideo() {
    await this.audioVideo?.stopVideoInput();
  }

  attachLocalVideo({ videoElement, mediaSource }) {
    if (this.featureThChime) {
      this.meetingManager.audioVideo?.startVideoPreviewForVideoInput(videoElement);
      videoElement.style.transform = '';
      return;
    }

    if (mediaSource) return mediaSource.attach(videoElement);

    this.twilioVideoTrack?.attach(videoElement);
  }

  detachLocalVideo(element) {
    if (!this.featureThChime) return;

    this.meetingManager.audioVideo?.stopVideoPreviewForVideoInput(element);
  }

  createLocalVideo() {
    if (this.featureThChime) return this.localAudioVideo.startLocalVideo();

    return this.twilioRoom.createVideoTrack({
      deviceId: this.selectedVideoDeviceId,
      userName: this.userName,
    });
  }

  setVideoProcessor({ track, type }) {
    return this.featureThChime
      ? this.localAudioVideo.updateVideoProcessor(type)
      : this.twilioRoom.setVideoProcessorType(track, type);
  }

  initScreenSharingVideo(element) {
    if (this.featureThChime) {
      this.contentSharing.bindScreenSharingTile(element);
    } else {
      this.attachMediaSource({
        element,
        source: this.screenShareVideoSource,
      });
    }
  }

  initScreenSharingAudio(element) {
    if (this.featureThChime) return;

    this.attachMediaSource({
      element,
      source: this.screenShareAudioSource,
    });
  }

  attachMediaSource({ element, source }) {
    if (!source) return;

    source.attach(element);

    if (element.tagName === 'VIDEO') {
      element.addEventListener('enterpictureinpicture', this.handleEnterPictureInPicture);
      element.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);

      let sourceElement = document.createElement('source');
      sourceElement.src = source.id;
      element.appendChild(sourceElement);

      return () => {
        if (element.isConnected || document.pictureInPictureElement !== element) return;

        document.exitPictureInPicture();
      };
    }
    if (!this.selectedAudioOutputDeviceId) return;
    element.setSinkId?.(this.selectedAudioOutputDeviceId);
  }

  async toggleLocalVideo() {
    if (this.featureThChime) {
      return await this.localAudioVideo.toggleVideo();
    }

    return this.twilioRoom.toggleLocalVideo();
  }

  toggleLocalAudio() {
    if (this.featureThChime) {
      let isMuted = !this.localAudioVideo.isAudioPublished;

      return isMuted
        ? this.meetingManager.audioVideo.realtimeUnmuteLocalAudio()
        : this.meetingManager.audioVideo.realtimeMuteLocalAudio();
    }

    return this.twilioRoom.toggleLocalAudio();
  }

  hasVideo(attendeeId) {
    if (attendeeId === this.meetingManager.localAttendeeId) {
      return !!this.localAudioVideo.tileId;
    }

    return !!this.remoteVideos.attendeeIdToTileId[attendeeId];
  }

  @action
  toggleScreenSharing() {
    return this.featureThChime
      ? this.contentSharing.toggleScreenSharing()
      : this.twilioRoom.toggleScreenSharing();
  }

  toggleWhiteboardSharing() {
    return this.featureThChime
      ? this.contentSharing.toggleWhiteboardSharing()
      : this.twilioRoom.toggleWhiteboardSharing();
  }

  @action
  toggleSharing() {
    switch (this.sharedEntity) {
      case SHAREABLE_ENTITIES.screen:
        this.toggleScreenSharing();
        break;
      case SHAREABLE_ENTITIES.whiteboard:
        this.toggleWhiteboardSharing();
        break;
      default:
        return;
    }
  }

  toggleWhiteboardLocked() {
    return this.featureThChime
      ? this.contentSharing.toggleWhiteboardLocked()
      : this.twilioRoom.toggleWhiteboardLocked();
  }

  shareWhiteboardVideo(videoStream) {
    return this.featureThChime
      ? this.contentSharing.shareVideoStream(videoStream)
      : this.twilioRoom.shareWhiteboardTrack(videoStream.getVideoTracks()[0]);
  }

  leave() {
    return this.featureThChime ? this.appointment.leave() : this.videoStream.disconnect();
  }
}
