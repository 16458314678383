import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { CreateChannelModeratorResponseFilterSensitiveLog, } from "../models/models_0";
import { de_CreateChannelModeratorCommand, se_CreateChannelModeratorCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class CreateChannelModeratorCommand extends $Command
    .classBuilder()
    .ep({
    ...commonParams,
})
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "CreateChannelModerator", {})
    .n("ChimeSDKMessagingClient", "CreateChannelModeratorCommand")
    .f(void 0, CreateChannelModeratorResponseFilterSensitiveLog)
    .ser(se_CreateChannelModeratorCommand)
    .de(de_CreateChannelModeratorCommand)
    .build() {
}
