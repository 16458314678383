import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { trimmedName } from 'frontend/utils/name-utils';
import ActionButton from 'frontend/components/action-button';
import Component from '@glimmer/component';
import styles from './screen-sharing-container.module.scss';
export default class CallScreenSharingContainer extends Component {
    @service
    audioVideoAdapter;
    @reads('audioVideoAdapter.shareParticipant')
    shareParticipant;
    @reads('audioVideoAdapter.isSharingScreen')
    isSharingScreen;
    @action
    stopScreenSharing() {
        this.audioVideoAdapter.toggleScreenSharing();
    }
    get trimmedName() {
        return trimmedName(this.shareParticipant.name);
    }
    initVideo = modifier((element1)=>this.audioVideoAdapter.initScreenSharingVideo(element1));
    initAudio = modifier((element1)=>this.audioVideoAdapter.initScreenSharingAudio(element1));
    static{
        template(`
    <div class={{styles.component}} ...attributes>
      <div class="screen-preview">
        <div class="participant-container screen">
          <video {{this.initVideo}} muted />
          {{#if this.audioVideoAdapter.screenShareAudioSource}}
            {{! template-lint-disable require-media-caption }}
            <audio {{this.initAudio}} />
          {{/if}}
        </div>
      </div>
      {{#if this.isSharingScreen}}
        <ActionButton
          @title="Stop sharing"
          @icon="control-stop-sharing"
          @onClick={{this.stopScreenSharing}}
          class="stop-sharing-center-button"
          data-test-action-button
        />
      {{/if}}
      <div class="name">
        {{this.trimmedName}}'s screen
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
