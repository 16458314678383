/* import __COLOCATED_TEMPLATE__ from './panel.hbs'; */
import { JOIN_NOTIFICATION_ACTIONS } from 'frontend/constants/mixpanel';
import { SETTINGS_TABS } from 'frontend/constants/settings';
import { SHAREABLE_ENTITIES } from 'frontend/constants';
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { trimmedName } from 'frontend/utils/name-utils';
import Component from '@glimmer/component';
import styles from './panel.module.scss';

export default class CallHeaderControlsPanel extends Component {
  @service('chime.content-sharing') contentSharing;
  @service('chime.roster') roster;
  @service('twilio/video-stream') videoStream;
  @service('twilio/room') room;
  @service persistentProperties;
  @service session;
  @service floatingUiElements;
  @service mediaSettings;
  @service mixpanel;
  @service uiElements;
  @service audioVideoAdapter;
  @service call;

  settingsTabs = SETTINGS_TABS;
  styles = styles;

  @reads('roster.size') rosterSize;
  @reads('call.isConnected') isConnected;
  @reads('contentSharing.shareAttendeeId') shareAttendeeId;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('session.inWaitingRoom') inWaitingRoom;
  @reads('session.isAuthenticated') isAuthenticated;
  @reads('session.pendingRequestsNumber') pendingRequestsNumber;
  @reads('audioVideoAdapter.isSharing') isSharing;
  @reads('audioVideoAdapter.isWhiteboarding') isWhiteboarding;
  @reads('audioVideoAdapter.sharedEntity') sharedEntity;

  get requestNotificationShown() {
    return this.session.isHost && this.pendingRequestsNumber && this.joinRequestNotificationHidden;
  }

  get controlsShown() {
    return this.floatingUiElements.controlsShown;
  }

  get joinRequestNotificationHidden() {
    return this.floatingUiElements.joinRequestNotificationHidden;
  }

  get isSharingSubscriber() {
    return !this.isSharing && this.sharedEntity;
  }

  get hideSelfView() {
    return this.featureThChime ? !this.uiElements.isSelfViewShown : this.videoStream.hideSelfView;
  }

  get gridView() {
    return this.featureThChime ? this.uiElements.isGridView : this.videoStream.gridView;
  }

  get noParticipants() {
    return this.featureThChime ? !this.rosterSize : this.videoStream.noParticipants;
  }

  get isRecording() {
    return this.session.isRecording;
  }

  get controlsList() {
    let controls = this.inWaitingRoom
      ? []
      : [
          {
            activeName: 'Hide self view',
            activeIcon: 'eye',
            name: 'Show self view',
            icon: 'crossed-eye',
            separator: this.isRecording,
            onClick: this.toggleSelfView,
            active: !this.hideSelfView,
            ariaLabel: this.hideSelfView ? 'Show self view' : 'Hide self view',
          },
          {
            activeName: 'Grid view',
            activeIcon: 'grid-view',
            name: 'Speaker view',
            icon: 'speaker-view',
            onClick: this.toggleGridView,
            disabled: this.noParticipants || this.isWhiteboarding,
            active: !this.gridView,
            ariaLabel: this.gridView ? 'Toggle to speaker view' : 'Toggle to grid view',
          },
        ];

    if (!this.isAuthenticated) {
      controls.push({
        activeIcon: 'gear',
        icon: 'gear',
        separator: !this.inWaitingRoom,
        tooltip: this.featureThClinicianAuth ? 'Telehealth settings' : 'Settings',
        onClick: this.openSettings,
        active: !this.floatingUiElements.isSettingsModalShown,
        ariaLabel: 'Settings',
      });
    }

    if (this.isRecording && !this.session.isHost) {
      controls.unshift({
        activeName: 'Recording',
        activeIcon: 'recording',
        tooltip: 'Recording in progress',
        active: true,
        ariaLabel: 'Recording in progress',
      });
    }

    return controls;
  }

  get sharingParticipantName() {
    return this.featureThChime
      ? this.roster.getAttendee(this.shareAttendeeId)?.name
      : this.room.shareParticipant.name;
  }

  get sharingTitle() {
    if (this.isSharingSubscriber) {
      return `${trimmedName(this.sharingParticipantName)} is sharing ${
        this.isWhiteboarding ? 'a Whiteboard' : 'screen'
      }`;
    } else {
      return `You are sharing ${this.isWhiteboarding ? 'a Whiteboard' : 'your screen'}`;
    }
  }

  get sharingIcon() {
    return this.sharedEntity === SHAREABLE_ENTITIES.screen ? 'computer-screen' : 'whiteboard';
  }

  @action
  toggleSelfView() {
    return this.featureThChime
      ? this.uiElements.toggleSelfView()
      : this.videoStream.toggleHideSelfView();
  }

  @action
  openSettings(tabName) {
    this.mediaSettings.openSettingsModal(tabName);
  }

  @action
  toggleGridView() {
    return this.featureThChime
      ? this.uiElements.toggleGridView()
      : this.videoStream.toggleGridView();
  }

  @action
  toggleSharing() {
    this.audioVideoAdapter.toggleSharing();
  }

  @action
  onSettingsButtonClick() {
    this.floatingUiElements.setProfilePopoverVisibility(false);
    this.openSettings();
  }

  @action
  onRequestNotificationClick() {
    if (this.pendingRequestsNumber === 1) {
      this.floatingUiElements.showShortJoinRequestNotification();
    } else {
      this.floatingUiElements.showDetailedJoinRequestNotification();
    }
    this.mixpanel.track('host admit notification interaction', {
      action: JOIN_NOTIFICATION_ACTIONS.expand,
    });
  }
}
